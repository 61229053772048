<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from 'axios';
import { required, email } from "vuelidate/lib/validators";
import appConfig from "@/app.config";

export default {
  components: {
    Layout,
    PageHeader
  },
  page: {
    title: "Usuários",
    meta: [{ name: "description", content: appConfig.description }]
  },
  data() {
    return {
      title: "Detalhe usuário",
      items: [
        {
          text: "Usuários",
          href: "/detailUser"
        },
        {
          text: "Detalhe",
          active: true
        }
      ],
      detailUser: null,
      img_url: null,
      expandImg: null,
      user: {name: '', email: ''},
      submitted: false,
    };
  },
  validations: {
    user: {
      name: { required },
      email: { required, email }
    }
  },
  created(){
    const user = JSON.parse(localStorage.getItem('user'));
    let config = {
      method: 'get',
      url: `${process.env.VUE_APP_API}api/v1/admin/user/${this.$route.params.id}`,
      headers: { 
        'Accept': 'application/json', 
        'Authorization': `Bearer ${user.access_token}`
      }
    };

    axios(config)
    .then( (response) => {
      this.detailUser = response.data.data.user
      this.title = this.detailUser.name
    })
    .catch((error) => {
      console.log(error)
      this.$router.push('/pages/error-404')
    });

    this.img_url = process.env.VUE_APP_IMG
  },
  methods: {
    changeStatus(status, id){
      const user = JSON.parse(localStorage.getItem('user'));
      let config = {
        method: 'PUT',
        url: `${process.env.VUE_APP_API}api/v1/lot/disable/${id}`,
        headers: { 
          'Accept': 'application/json', 
          'Authorization': `Bearer ${user.access_token}`
        },
        data:{
          enable: status
        }
      };

      axios(config)
      .then( (response) => {
        response
      })
    },
    loginAs(){
      const user = JSON.parse(localStorage.getItem('user'));
      let config = {
        method: 'POST',
        url: `${process.env.VUE_APP_API}api/v1/admin/user/login-as`,
        headers: { 
          'Accept': 'application/json', 
          'Authorization': `Bearer ${user.access_token}`
        },
        data:{
          uuid: this.detailUser.uuid
        }
      };

      axios(config)
      .then( (response) => {
        if(response.status == 200){
          window.open(`${process.env.VUE_APP_SITE}/login-as?t=${response.data.data.access_token}`, '_blank').focus();
        }
      })
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-overlay :show="submitted" rounded="sm">
      <div class="row mb-5" v-if="detailUser">
        <div class="col-sm-6">
          <div class="card h-100">
            <div class="card-header bg-transparent border-bottom">
              <h5 class="mb-0">Detalhes</h5>
            </div>

            <div class="card-body border-top">
              <div class="media">
                <div class="media-body align-self-center overflow-hidden">
                  <p class="text-muted mb-0">Nome</p>
                  <h6>
                    {{detailUser.name}} <button class="btn btn-info btn-sm ml-4" @click="loginAs">Logar no site</button>
                  </h6>
                  <hr>
                </div>
              </div>
              <div class="media">
                <div class="media-body align-self-center overflow-hidden">
                  <p class="text-muted mb-0">Email</p>
                  <h6>
                    {{detailUser.email}}
                    <span class="text-success font-size-12 ml-2" v-if="detailUser.email_valid">Verificado</span>
                    <span class="text-danger font-size-12 ml-2" v-else>Não verificado</span>
                    </h6>
                  <hr>
                </div>
              </div>
              <div class="media">
                <div class="media-body align-self-center overflow-hidden">
                  <p class="text-muted mb-0">CPF</p>
                  <h6>{{detailUser.identity}}</h6>
                  <hr>
                </div>
                
              </div>
              <div class="media">
                <div class="media-body align-self-center overflow-hidden">
                  <p class="text-muted mb-0">Telefone</p>
                  <h6>
                    {{detailUser.phone}}
                    <span class="text-success font-size-12 ml-2" v-if="detailUser.phone_valid">Verificado</span>
                    <span class="text-danger font-size-12 ml-2" v-else>Não verificado</span>
                  </h6>
                  <hr>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="col-sm-6">
          <div class="card h-100">
            <div class="card-header bg-transparent border-bottom">
              <h5 class="mb-0">Carteira digital</h5>
            </div>

            <div class="card-body border-top" v-if="detailUser.wallet">

              <div class="media">
                <div class="media-body align-self-center overflow-hidden">
                  <p class="text-muted mb-0">Saldo em conta</p>
                  <h3>R$ {{detailUser.wallet.balance | price }}</h3>
                  <hr>
                  <p class="text-muted mb-0">Valores à receber</p>
                  <h6>R$ {{detailUser.wallet.to_receive | price }}</h6>
                  <hr>
                </div>
              </div>

              <h6>Transações</h6>
              <div style="max-height: 300px;overflow: scroll;">
                <table class="table">
                  <thead class="thead-light">
                    <tr>
                      <th>Tipo</th>
                      <th>Valor</th>
                      <th>Data</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in detailUser.wallet.last_movements" :key="index"  :class="item.entrance ? 'table-success' : 'table-danger'">
                      <td>
                        <strong v-if="item.entrance" class="text-primary">C</strong>
                        <strong v-else class="text-danger">D</strong>
                      </td>
                      <td>
                        R$ {{item.value | price}}
                        <br>
                        <small>
                          {{item.description}}
                        </small>
                      </td>
                      <td>
                        {{item.executed_date | date}}
                      </td>
                      <td>
                        {{item.status_text}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="card-body border-top" v-else></div>
          </div>
        </div>


        <div class="col-sm-12 mt-4">
          <div class="card h-100">
            <div class="card-header bg-transparent border-bottom">
              <h5 class="mb-0">Lotes</h5>
            </div>

            <div class="card-body border-top">
              <table
                  class="table table-centered datatable dt-responsive nowrap"
                  style="border-collapse: collapse; border-spacing: 0; width: 100%;"
                >
                  <thead class="thead-light">
                    <tr>
                      
                      <th>Lote</th>
                      <th>Valor</th>
                      <th>Tipo</th>
                      <th>Status</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in detailUser.lots" :key="index">
                      
                      <td>{{item.title}}</td>
                      <td>R$ {{item.amount | price}}</td>
                      <td>
                        <div
                          class="badge font-size-12"
                          :class="{'badge-soft-danger': `${item.single_sale}` == 1,
                          'badge-soft-success': `${item.single_sale}` == 2,
                          'badge-soft-warning': `${item.single_sale}` == 3}"
                        >{{ item.single_sale == 1 ? 'Venda direta' : 'Venda por lance'}}</div>
                      </td>   
                      <td>
                        <b-form-checkbox switch v-model="item.checked" @change="changeStatus(item.checked, item.uuid)" size="lg"></b-form-checkbox>
                      </td>
                      <td>
                        <router-link
                          class="mr-3 text-primary"
                          v-b-tooltip.hover
                          title="Editar"
                          :to="`/lot/${item.uuid}`"
                        >
                          <i class="mdi mdi-eye font-size-18"></i>
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
            </div>
          </div>
        </div>

        <div class="col-sm-12 mt-4">
          <div class="card h-100">
            <div class="card-header bg-transparent border-bottom">
              <h5 class="mb-0">Animais</h5>
            </div>

            <div class="card-body border-top">
              <table class="table mb-0">
                  <thead>
                    <tr>
                      <th>#Registro</th>
                      <th>Nome</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(animal, index) in detailUser.animals" :key="index">
                      <th scope="row">{{animal.registry}}</th>
                      <td>{{animal.name}}</td>
                      <td>
                        <b-button size="sm" variant="primary" :to="`/animals/${animal.uuid}`" v-slot="{}">
                          Visualizar
                          <i class="ri-eye-fill align-middle ml-2"></i>
                        </b-button>
                      </td> 
                    </tr>
                    
                  </tbody>
                </table>
            </div>
          </div>
        </div>

        <div class="col-sm-12 mt-4" v-if="detailUser.bigbooster_json">
          <div class="card h-100">
            <div class="card-header bg-transparent border-bottom">
              <h5 class="mb-0">Detalhes usuário</h5>
            </div>

            <div class="card-body border-top">
                <div v-for="(data, key) in JSON.parse(detailUser.bigbooster_json)" :key="key">
                  <p v-if="typeof data != 'object'">
                    <strong>{{key}}: </strong>{{data}}
                  </p>
                </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end row -->

      <b-row v-else>
        <div class="col-sm-12">
          <b-skeleton-img height="400px"></b-skeleton-img>
        </div>
      </b-row>
    </b-overlay>
  </Layout>
</template>